<template>
    <div class="container-fluid p-2">
        <!-- <h3 class="text-center mt-2">
            Following is the phone number of MS
        </h3> -->
        <h3 class="text-center mb-3 mt-2">Our Service is 24/7</h3>
        <div class="card">
            <!-- <div class="card-header">
                <h4>Customer Service Phone</h4>
            </div> -->
            <div class="card-body">
                <div class="d-flex justify-content-between d-flex">
                    <!-- <div class="my-auto">+959253560382</div>
                    <a
                        href="viber://contact?number=%2B959253560382"
                        class="d-flex p-2"
                        style="border-radius: 5px; background: #cbe8fe"
                    >
                        <img
                            src="/build/assets/img/theme/viber.png"
                            alt=""
                            style="width: 25px; height: 25px"
                        />
                        <h3 class="my-auto mx-2">Viber</h3>
                    </a> -->
                    <img
                        src="/build/assets/img/theme/contact_info.jpg"
                        alt=""
                        style="width: 90%; height: 300px; border-radius: 5px"
                        class="m-auto"
                    />
                </div>
                <hr />
                <div class="d-flex justify-content-between mt-3">
                    <!-- <div class="my-auto">+959677468579</div> -->
                    {{websiteInfo.contact_info}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            websiteInfo: []
        }
    },
    mounted(){
        if (!localStorage.getItem('token')) {
            this.$router.push('/auth/login');
        }
        this.fetchWebsiteData()
    },
    methods: {
        async fetchWebsiteData() {
        try {
            const res = await axios.get('/website-infos');
            this.websiteInfo = res.data.data[0];
        } catch (error) {
            console.log(error);
        }
    },
    }
}
</script>